// import { cloneDeep } from 'lodash';
import Vue from 'vue';
import { getEntityByKeyList } from '../utils';

const state = Vue.observable({
  case_id: '',
  list: [],
  dependencies: {},
  linked_blocks: [],
  personal_details: null,
  gap_errors: null,
  gap_values: {},
  utils: {},
});

export const mutations = {
  handleGapErrors(payload) {
    let checkgapError = localStorage.getItem('gapErrorValue_'+payload.id);
    if (checkgapError == undefined || checkgapError || checkgapError == null) {
      localStorage.setItem('gapErrorValue_'+payload.id, JSON.stringify(payload));
    }
    state.gap_errors = payload;
    if (!payload?.gaps) return;
    state.gap_errors.gaps.forEach(this.setGapGroup);
  },
  setGapGroup(payload) {
    // Use 'block_id' prop as a group identifier
    // Use 'block_fe_id' as a particulat timeline identifier
    Vue.set(state.gap_values, payload.block_id, {
      ...payload,
      timeline: payload.timeline.map(gap => {
        if (gap.invalid_gap) return gap;
        const relatedBlock = getEntityByKeyList(state.list, { block_fe_id: gap.block_fe_id });
        const relatedField = getEntityByKeyList(relatedBlock.entities, { field_id: gap.field_id });
        return {
          ...gap,
          block: relatedBlock,
          field: relatedField,
        }
      }),
    });
    localStorage.setItem('gapTimeline_'+payload?.block_id, JSON.stringify(payload));
  },
  setFilledGap(field, block, gap) {
    // for filled gaps we will use values from related fields
    const newGap = {
      ...gap,
      frontend: true,
      block,
      field,
      invalid_gap: false,
    }
    state.gap_values[block.block_id].timeline.push(newGap);
  },

  /**
   * 
   * @param {*} sec_id uuid of the section, you want to change status of
   * @param {*} status status of the section, ERROR, PENDING, COMPLETED etc,.
   */
  setSectionStatus(sec_id, sectionStatus) {
    let index = state.list.findIndex(el => el.section_id === sec_id)
    Vue.set(state.list, index, {...state.list[index], status: sectionStatus, candidate_status: sectionStatus})
  },

  /**
   * 
   * @param {Object} block , block  you want to re-order
   * @param {String} section_id , the section's id in which the block exists
   * @param {String} direction , can be up or down depending on the block's position in the block-group-list
   */
  handleOrder(block, section_id, direction) {
    // let currentSection = state.list.find(sec => sec.section_id === section_id)
    // currentSection.entities.splice()
    const moveInArray = function (arr, from, to) {
      // Make sure a valid array is provided
      if (Object.prototype.toString.call(arr) !== '[object Array]') {
        throw new Error('Please provide a valid array');
      }
      // Delete the item from it's current position
      var item = arr.splice(from, 1);
      // Make sure there's an item to move
      if (!item.length) {
        throw new Error('There is no item in the array at index ' + from);
      }
      // Move the item to its new position
      arr.splice(to, 0, item[0]);
      arr.forEach((item, index) => {
        item.serial = index + 1;
      });
    };

    // ! When you reassign a section/entities you lost all the references to its entities which are used for dependent fields, linked blocks, gap validation etc.
    // ! you shouldn't go through all the section to change one particular
    // state.list = state.list.map(sec => {
    //   if (sec.section_id === section_id ) {
    //     let blockEntities = cloneDeep(sec.entities)
    //     // ! change order here 'serial'
    //     const blockToOrderIndex = blockEntities.findIndex(blc => blc.block_fe_id === block.block_fe_id)
    //     const indexToMove = direction === 'up' ? blockToOrderIndex - 1 : blockToOrderIndex + 1
    //     blockEntities[blockToOrderIndex].serial = blockEntities[indexToMove].serial +  direction === 'up' ? -1 : 1
    //     blockEntities[indexToMove].serial = direction === 'up' ? -1 : 1
    //     moveInArray(blockEntities,indexToMove , blockToOrderIndex)
    //     return {...sec, entities: blockEntities}

    //   } else return sec
    // }) 
    const sectionToChange = state.list.find(sec => sec.section_id === section_id);
    const blockToOrderIndex = sectionToChange.entities.findIndex(blc => blc.block_fe_id === block.block_fe_id)
    const indexToMove = direction === 'up' ? blockToOrderIndex - 1 : blockToOrderIndex + 1
    sectionToChange.entities[blockToOrderIndex].serial = sectionToChange.entities[indexToMove].serial +  direction === 'up' ? -1 : 1
    moveInArray(sectionToChange.entities, indexToMove , blockToOrderIndex);
  },
  // markDuplicateFields(){
  //   const duplicateFields = {}
  //   state.list?.forEach((sec) => {
  //     let sectionHide = true;
  //     sec.entities.forEach((bl) => {
  //       if (bl.form_entity_type === "BLOCK") {
  //           // let blockHide = true;
  //           // bl.entities.forEach((field) => {
  //           //     if (field.is_hide_duplicate_field) {
  //           //         if (Object.keys(duplicateFields).includes(field.label)) {
  //           //             field.isDuplicate = true;
  //           //         }
  //           //         else {
  //           //             duplicateFields[field.label] = field.field_id;
  //           //             field.isDuplicate = false;
  //                       sectionHide = false;
  //           //             blockHide = false;
  //           //         }
  //           //     }
  //           //     else{sectionHide = false;blockHide = false;}
  //           // })
  //           // bl.blockHide = blockHide;
  //       }
  //       if (bl.form_entity_type === "FIELD") {
  //           if (bl.is_hide_duplicate_field) {
  //               if (Object.keys(duplicateFields).includes(bl.label)) {
  //                   bl.isDuplicate = true;
  //               }
  //               else {
  //                   duplicateFields[bl.label] = bl.field_id;
  //                   bl.isDuplicate = false;
  //                   sectionHide=false;
  //               }
  //           }
  //           else{sectionHide = false;}
  //       }
  //   })
  //   sec.sectionHide= sectionHide;
  // })}
}

export default state;

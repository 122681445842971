<template>
  <!-- Use v-show to render fields inside and do necessary calculations -->
  <div>
  <div
    v-show="isShown && !blockData.blockHide"
    class="border-2 border-green-600 py-5 px-4 flex items-start justify-stretch gap-2 relative bg-gray-100 border form_block"
    :class="{
      'rounded-xl': !grouped,
      'rounded-t-xl': grouped && isLastOrFirst(blockData.block_id, blockData.block_fe_id, true).is_first,
      'rounded-b-xl': grouped && isLastOrFirst(blockData.block_id, blockData.block_fe_id, false).is_last,
      'border-b border-line': grouped && !isLastOrFirst(blockData.block_id, blockData.block_fe_id, false).is_last,
      'border border-red-600': validations && validations.$error && isCollapsed,
      'borderWidth' :  posInGroup > 0 && (!isCollapsed || isDateRangeBlock && windowWidth> 1080 || !isDateRangeBlock )|| isLinkBlockDisable,
      'boederWidthDate': this.isCopy && (isDateRangeBlock && windowWidth < 499) || isLinkBlockDisable,
      'responsive_block': previewForm,
      'form_block': !isCollapsed || isDateRangeBlock && windowWidth> 1080|| !isDateRangeBlock,
      'dateBlockHide': isDateRangeBlock,
      'pb-0' : isCollapsed && !canBeCopied,
      'openBlock' : !isCollapsed
    }"
    :id="blockData.block_fe_id"
    ref="block-container"
  >
    <!-- <div class="p-2 bg-blue-400 border border-b-0 border-blue-500 -mx-2 rounded-t-lg"></div> -->
    <span
      class="text-primary btn btn-ghost flex-shrink-0 no-print svgCollapse"
      @click="toggleCollapse()"
      :disabled="previewForm || formAlreadySubmitted"
      :class="{ hideLabel: previewForm, 'svgCollapse': !isCollapsed || isDateRangeBlock && windowWidth> 1080|| !isDateRangeBlock}"
    >
      <svg
        class="transition-all"
        :class="{ 'transform rotate-90': !isCollapsed }"
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.847628 0.222752C0.699168 0.371088 0.624999 0.546925 0.625 0.750097L0.625 11.2499C0.625 11.4532 0.699168 11.6289 0.847628 11.7774C0.996252 11.9258 1.17197 12 1.3751 12C1.57819 12 1.7539 11.9258 1.9024 11.7774L7.15235 6.52743C7.30081 6.3788 7.37514 6.20309 7.37514 6C7.37514 5.79691 7.30081 5.62103 7.15235 5.47269L1.90236 0.222752C1.7539 0.0744552 1.57819 -4.16652e-08 1.37506 -3.2786e-08C1.17197 -2.39086e-08 0.996252 0.0744552 0.847628 0.222752Z"
          fill="#0D69D5"
        />
      </svg>
    </span>
    <div class="block-body flex-1 flex flex-col">
      <!--* header  -->
      <div class="block-header flex justify-between blockHeader" :class="{'borderHeader' : posInGroup > 0 || isLinkBlockDisable,'blockHeader':!isCollapsed || isDateRangeBlock && windowWidth> 1080|| !isDateRangeBlock, 'collapesBlock' : isCollapsed, 'nativeFormBlock' : previewForm}">
      <div class="flex items-center">
          <h3
            v-if="blockData.block_name "
            :class="{responsiveLabel: previewForm, }"
            class="text-base capitalize text-base-content mb-0 h-8 flex items-center mb-4 blockLable"
          >
          <span
            class="text-primary btn btn-ghost flex-shrink-0 no-print svgCollapseBlock  hidden-padding"
            @click="toggleCollapse()"
            :disabled="formAlreadySubmitted"
            :class="{ hideLabel: previewForm }"
             >
           <svg
            class="transition-all svgCollAll print--none"
            :class="{ 'transform rotate-90': !isCollapsed }"
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
          <path
            d="M0.847628 0.222752C0.699168 0.371088 0.624999 0.546925 0.625 0.750097L0.625 11.2499C0.625 11.4532 0.699168 11.6289 0.847628 11.7774C0.996252 11.9258 1.17197 12 1.3751 12C1.57819 12 1.7539 11.9258 1.9024 11.7774L7.15235 6.52743C7.30081 6.3788 7.37514 6.20309 7.37514 6C7.37514 5.79691 7.30081 5.62103 7.15235 5.47269L1.90236 0.222752C1.7539 0.0744552 1.57819 -4.16652e-08 1.37506 -3.2786e-08C1.17197 -2.39086e-08 0.996252 0.0744552 0.847628 0.222752Z"
            fill="#0D69D5"
          />
          </svg>
          </span>
           <span class="blockCount">{{posInGroup+1}}</span> {{ blockData.block_label }}
            <!-- <span class="" @click="isCollapsed = !isCollapsed">Expand</span> -->
          </h3>
          <div v-else-if="isCollapsed">
          <!-- Show names of two firs fields if it is collapsed group -->
            <h3
              v-for="entity in blockData.entities.filter(el => el.form_entity_type === 'FIELD').slice(0, 2)"
              :key="entity.field_fe_id || entity.field_id"
              class="h-8 flex items-center"
            >
              {{ entity.label }}
            </h3>
          </div>
        </div>
        <div v-if="!(grouped && !isCollapsed)" class="flex items-center">
          <h3
            v-if="blockData.block_name && !showGapErrors && !grouped || isDateRangeBlock && windowWidth> 1080|| !isDateRangeBlock"
            :class="{responsiveLabel: previewForm, 'blockLableData' : isDateRangeBlock && windowWidth> 1080|| !isDateRangeBlock}"
            class="text-base capitalize text-base-content mb-0 h-8 flex items-center mb-4   blockLable"
          >
          <span
            class="text-primary btn btn-ghost flex-shrink-0 svgCollapseBlock"
            @click="toggleCollapse()"
            :disabled="previewForm || formAlreadySubmitted"
            :class="{ hideLabel: previewForm }"
             >
           <svg
            class="transition-all svgCollAll"
            :class="{ 'transform rotate-90': !isCollapsed }"
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
          <path
            d="M0.847628 0.222752C0.699168 0.371088 0.624999 0.546925 0.625 0.750097L0.625 11.2499C0.625 11.4532 0.699168 11.6289 0.847628 11.7774C0.996252 11.9258 1.17197 12 1.3751 12C1.57819 12 1.7539 11.9258 1.9024 11.7774L7.15235 6.52743C7.30081 6.3788 7.37514 6.20309 7.37514 6C7.37514 5.79691 7.30081 5.62103 7.15235 5.47269L1.90236 0.222752C1.7539 0.0744552 1.57819 -4.16652e-08 1.37506 -3.2786e-08C1.17197 -2.39086e-08 0.996252 0.0744552 0.847628 0.222752Z"
            fill="#0D69D5"
          />
          </svg>
          </span>
           {{ blockData.block_label }}
            <!-- <span class="" @click="isCollapsed = !isCollapsed">Expand</span> -->
          </h3>
          <div v-else-if="isCollapsed">
          <!-- Show names of two firs fields if it is collapsed group -->
            <h3
              v-for="entity in blockData.entities.filter(el => el.form_entity_type === 'FIELD').slice(0, 2)"
              :key="entity.field_fe_id || entity.field_id"
              class="h-8 flex items-center"
            >
              <!--{{ entity.label }}-->
            </h3>
          </div>
        </div>
        <div class="block_controls flex justify-end gap-1 absolute top-1 right-4">
          <!-- class="cursor-pointer h-7 border border-gray-700 w-20 min-w-min px-3 hover:bg-gray-100 c rounded-lg text-sm text-black flex justify-center items-center font-semibold" -->
          <!-- <pre>
                  {{blockData.block_id}}
                  {{blockData.is_copy}}
                  {{blockData.block_fe_id}}
              </pre> -->
          <button
            class="btn btn-ghost btn-sm btn-circle no-print"
            v-if="
              !isLastOrFirst(blockData.block_id, blockData.block_fe_id, false)
                .is_last
            "
            @click="handleOrder(blockData, 'down')"
            title="Move Down"
            :disabled="isDisabled || isLinkBlockDisable || previewForm || formAlreadySubmitted"
            :class="{ hideLabel:previewForm }"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.99999 14C6.90799 14 6.72399 14 6.63199 13.9125C6.53999 13.9125 6.44799 13.825 6.35599 13.7375L2.67599 10.2375C2.30799 9.8875 2.30799 9.3625 2.67599 9.0125C3.04399 8.6625 3.59599 8.6625 3.96399 9.0125L6.07999 11.025V0.875C6.07999 0.35 6.44799 0 6.99999 0C7.55199 0 7.91999 0.35 7.91999 0.875V11.025L10.036 9.0125C10.404 8.6625 10.956 8.6625 11.324 9.0125C11.692 9.3625 11.692 9.8875 11.324 10.2375L7.64399 13.7375C7.55199 13.825 7.45999 13.9125 7.36799 13.9125C7.27599 14 7.09199 14 6.99999 14Z"
                :fill="isDisabled || isLinkBlockDisable ? 'grey' : '#282828'"
              />
            </svg>
          </button>
          <button
            class="btn btn-ghost btn-sm btn-circle no-print"
            v-if="
              !isLastOrFirst(blockData.block_id, blockData.block_fe_id, true)
                .is_first
            "
            title="Move Up"
            @click="handleOrder(blockData, 'up')"
            :disabled="isDisabled || isLinkBlockDisable || formAlreadySubmitted"
            :class="{hideLabel:previewForm}"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.99998 0C7.09198 0 7.27598 9.53674e-07 7.36798 0.0875006C7.45998 0.0875006 7.55198 0.174999 7.64398 0.262499L11.324 3.7625C11.692 4.1125 11.692 4.6375 11.324 4.9875C10.956 5.3375 10.404 5.3375 10.036 4.9875L7.91998 2.975L7.91998 13.125C7.91998 13.65 7.55198 14 6.99998 14C6.44798 14 6.07998 13.65 6.07998 13.125L6.07998 2.975L3.96398 4.9875C3.59598 5.3375 3.04398 5.3375 2.67598 4.9875C2.30798 4.6375 2.30798 4.1125 2.67598 3.7625L6.35598 0.262499C6.44798 0.174999 6.53998 0.0875006 6.63198 0.0875006C6.72398 9.53674e-07 6.90798 0 6.99998 0Z"
                :fill="isDisabled || isLinkBlockDisable ? 'grey' : '#282828'"
              />
            </svg>
          </button>
        </div>
      </div>
      <!--* /header  -->
      <!-- {{isMulti}} -->
      <template v-if="showGapErrors">
        <!-- Since two form-builders (normal and preview) can be shown at the same time, we must use different portals -->
        <portal
          :to="`${blockData.block_fe_id}_name${previewForm ? '-preview' : ''}`"
        >
          <h3
            v-if="blockData.block_name && showGapErrors"
            class="text-lg capitalize font-semibold text-base-content mb-2 blockNameLable"
          >
            {{ blockData.block_label }}
          </h3>
        </portal>
        <portal
          :to="`${blockData.block_fe_id}_gaps${previewForm ? '-preview' : ''}`"
        >
          <GapErrors
            ref = 'scrollRef'
            class="block-gaps mb-4"
            :class="{'form-field--invalid': gapErrorMsg==='ERROR.GAP_VALIDATION'}"
            @select:gap="selectGap"
            :key="gap_errors.id"
            :message="gap_errors.message"
            :gapGroup="gapGroup"
            @reload="scrollToGaps"
            @showGapModal="showGapModal"
            @addBlock="addBlock"
            :previewForm="previewForm"
            :blockData="blockData"
            :formAlreadySubmitted="formAlreadySubmitted"
            :modalEdit="modalEdit"
          />
        </portal>
      </template>
      <template v-if="showGapErrors" >
        <portal
          :to="`${blockData.block_fe_id}_gaps_block${previewForm ? '-preview' : ''}`" 
        >
      <div class="tableView" v-for="(entity, idx) in blockData.entities"  :key="idx">
      <div v-if="entity.field_type === 'Date Range (Timeline)' && !entity.render_conditionally">
          <BlocksList 
          :section="section"
          :layoutData="entity"
          :blockData="blockData" 
          @addBlock="addBlock" 
          :validations="validations"
          :previewMode="previewMode"
          :previewForm="previewForm"
          :linkedBlock="linkedBlock"
          class="my-4"
          :path="path"
          @onCopyEntity = "onCopyEntity"
          @removeEntityBlock="removeEntityBlock"
          @editBlock="editBlock"
          @cloneBlock = "cloneBlock"
          :toggle="toggle"
          :sIndex="sIndex"
          :formAlreadySubmitted="formAlreadySubmitted"
          :firstBlockAdded="firstBlockAdded"
          :isGapClicked="isGapClicked"
          :sectionData="section.entities"
          :findErrors="findErrors"
          />
          </div>
          </div>
          </portal>
          </template>
      <!--* content -->
      <div class="block-content blockItems">
        <div
          v-if="!isCollapsed"
          class="block-form py-1 rounded-md flex flex-col items-stretch nativeBlockView"
          :class="{'blockItems-preview' : previewForm}"
          >
          <div
            class="blockFields"
            v-for="(entity, idx) in blockData.entities"
            :key="idx + (blockData._id || blockData.multi_id || blockData.block_id)"
          >
            <template v-if="entity.form_entity_type === 'LAYOUT'">
              <!-- TODO we should use field but not the layout entity in addTimeline -->
              <Layout
                :layoutData="entity"
                :path="[...path, { field: 'layout_id', value: entity.layout_id }]"
                :validations="validations ? validations[idx].entities : validations"
                :previewForm="previewForm"
                :previewMode="previewMode"
                :linkedBlock="linkedBlock"
                :disabled="isDisabled"
                :formAlreadySubmitted="formAlreadySubmitted"
                @addTimeline="addTimeline(entity)"
                @removeTimeline="removeTimeline"
                class="my-4"
              />
            </template>
            <template v-if="entity.form_entity_type === 'FIELD'">
             <FormField
                :ref="getFieldRef(entity)"
                :fieldData="entity"
                :parentData="blockData"
                :key="entity.field_id"
                :path="[
                  ...path,
                  { field: 'field_fe_id', value: entity.field_fe_id },
                ]"
                :isMulti="checkIfMulti(entity, blockData.entities)"
                :validations="validations ? validations[idx] : validations"
                :previewMode="previewMode"
                :previewForm="previewForm"
                :linkedBlock="linkedBlock"
                :disabled="isDisabled || isLinkBlockDisable"
                @addTimeline="addTimeline(entity)"
                @removeTimeline="removeTimeline"
                class="my-4"
                :hasSignature="hasSignature"
                :class="{inputLabel : previewForm}"
                :formAlreadySubmitted="formAlreadySubmitted"
                :isCopyBlock="isCopy"
                @checkCheckboxId="checkCheckboxId"
                :checkBoxId="checkBoxId"
                :isBlockDeleted="isBlockDeleted"
                @checkBoxData="checkBoxData"
              /> 
            </template>
          </div>
          <!-- <div class="flex text-right" v-if="showRemoveBtn">
                    <span class="ml-auto cursor-pointer capitalize text-brand hover:text-brand-hover" @click="removeBlock(blockData)"> - Remove {{ getBlockName }} </span>
                </div> -->
        </div>
        <div v-if="canBeCopied || canBeRemoved" class="flex justify-end mt-4 cloneAdd_block" :class="{addCloneBlock : previewForm,'cloneAdd_block': !isCollapsed || isDateRangeBlock && windowWidth> 1080|| !isDateRangeBlock, 'removeOnlyButton' : !canBeCopied}">
                <!-- // ADD BUTTON -->
            <!-- class="cursor-pointer h-7 w-20 min-w-min px-3 bg-primary hover:bg-primary-600 rounded-lg text-sm text-white-text flex justify-center items-center font-semibold disabled:opacity-50" -->
            <button
              class="btn btn-ghost btn-circle btn-sm no-print addBlock"
              @click="onCopyEntity()"
              v-if="canBeCopied"
              title="Add New"
              :disabled="previewForm || isDisabled || isLinkBlockDisable || formAlreadySubmitted"
              :class="{ 'addBlock': !isCollapsed || isDateRangeBlock && windowWidth> 1080|| !isDateRangeBlock, bgTransparent : previewForm }"
            >
              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" >
                      <rect x="0.40625" y="0.363281" width="16" height="16" rx="4" fill="white"/>
                      <path d="M5.40625 8.27539L11.4062 8.27539" stroke="#0D69D5" stroke-width="1.8" stroke-linecap="round"/>
                      <path d="M8.48828 11.3633V5.36328" stroke="#0D69D5" stroke-width="1.8" stroke-linecap="round"/>
                  </svg> -->
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_11_364)">
                  <path
                    d="M2.87438 7.59076C2.54188 7.25525 2.54188 6.71165 2.87438 6.37642C3.03406 6.21564 3.25063 6.12513 3.47621 6.12513H10.2903C10.7606 6.11693 11.1483 6.49455 11.1565 6.96869C11.1647 7.44283 10.7901 7.83384 10.3198 7.84205C10.31 7.84205 10.3001 7.84205 10.2903 7.84205H3.47648C3.25062 7.84287 3.03379 7.75236 2.87438 7.59076Z"
                    :fill="isDisabled || isLinkBlockDisable || previewForm ? 'grey' : '#282828'"
                  />
                  <path
                    d="M6.37013 11.1192C6.21263 10.9557 6.12431 10.7334 6.12513 10.5018V3.51354C6.1172 3.03119 6.48524 2.63361 6.94763 2.62514C7.41001 2.61666 7.79118 3.00084 7.79938 3.48318C7.79938 3.4933 7.79938 3.50342 7.79938 3.51354V10.5018C7.79938 10.9841 7.4245 11.3751 6.96212 11.3749C6.74009 11.3749 6.52735 11.283 6.3704 11.1192H6.37013Z"
                    :fill="isDisabled || isLinkBlockDisable || previewForm ? 'grey' : '#282828'"
                  />
                  <path
                    d="M10.0985 14H9.17843C8.83445 14 8.55554 13.7211 8.55554 13.3771C8.55554 13.0331 8.83445 12.7542 9.17843 12.7542H10.0985C10.7873 12.7542 11.4373 12.4835 11.9287 11.9921C12.42 11.5008 12.6907 10.8508 12.6907 10.162V3.8377C12.6907 3.14891 12.42 2.49895 11.9287 2.00758C11.4373 1.51621 10.7873 1.24551 10.0985 1.24551H9.17843C8.83445 1.24551 8.55554 0.966602 8.55554 0.622617C8.55554 0.278633 8.83417 0 9.17816 0H10.0983C12.2144 0 13.936 1.72156 13.936 3.8377V10.162C13.936 12.2782 12.2144 13.9997 10.0983 13.9997L10.0985 14Z"
                    :fill="isDisabled || isLinkBlockDisable || previewForm ? 'grey' : '#282828'"
                  />
                  <path
                    d="M4.75809 14H3.83797C1.72156 14 0 12.2784 0 10.1623V3.8377C0 1.72156 1.72156 0 3.8377 0H4.75781C5.1018 0 5.3807 0.278906 5.3807 0.622891C5.3807 0.966875 5.1018 1.24578 4.75781 1.24578H3.8377C3.14891 1.24578 2.49895 1.51648 2.00758 2.00785C1.51621 2.49922 1.24551 3.14918 1.24551 3.83797V10.1623C1.24551 10.8511 1.51621 11.5011 2.00758 11.9924C2.49895 12.4838 3.14891 12.7545 3.8377 12.7545H4.75781C5.1018 12.7545 5.3807 13.0334 5.3807 13.3774C5.3807 13.7214 5.1018 14.0003 4.75781 14.0003L4.75809 14Z"
                    :fill="isDisabled || isLinkBlockDisable || previewForm ? 'grey' : '#282828'"
                  />
                </g>
                <clipPath id="clip0_11_364">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </svg>
        
              <!-- Add -->
            </button>
            <!--Add button-->
            <button
              class="btn btn-ghost no-print AddButton mobile-hover-disable print--none"
              @click="onCopyEntity()"
              v-if="canBeCopied && !formAlreadySubmitted"
              title="Add New"
              :disabled="previewForm || isDisabled || isLinkBlockDisable || formAlreadySubmitted"
              :class="{ 'AddButton':!isCollapsed || isDateRangeBlock && windowWidth> 1080|| !isDateRangeBlock, 'addRange': isDateRangeBlock && windowWidth < 499,'bgTransparent' : previewForm , 'hideAddBtn' : previewForm  }"
               >
              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" >
                      <rect x="0.40625" y="0.363281" width="16" height="16" rx="4" fill="white"/>
                      <path d="M5.40625 8.27539L11.4062 8.27539" stroke="#0D69D5" stroke-width="1.8" stroke-linecap="round"/>
                      <path d="M8.48828 11.3633V5.36328" stroke="#0D69D5" stroke-width="1.8" stroke-linecap="round"/>
                  </svg> -->
                  Add New +
              <!-- Add -->
            </button>
            <button
              class="btn btn-ghost btn-sm btn-circle no-print btnClone print--none"
              :class="{'btnClone': !isCollapsed || isDateRangeBlock && windowWidth> 1080|| !isDateRangeBlock, 'bgTransparent': previewForm, 'cursor-not-allowed': isCloneBlockEnable.length ==0, 'disableCloneBlock': isCloneBlockEnable.length==0}"
              @click="cloneEntity"
              v-if="canBeCopied && !formAlreadySubmitted"
              :title="getCloneTitleText"
              :disabled="previewForm || isDisabled || isLinkBlockDisable || formAlreadySubmitted"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_11_358)">
                  <path
                    d="M4.86377 11.2725C3.68586 11.2725 2.72754 10.3141 2.72754 9.13623V3.58203H1.4458C0.737898 3.58203 0.164062 4.15587 0.164062 4.86377V12.5542C0.164062 13.2621 0.737898 13.8359 1.4458 13.8359H9.13623C9.84413 13.8359 10.418 13.2621 10.418 12.5542V11.2725H4.86377Z"
                    :fill="isCloneBlockEnable.length == 0 || isLinkBlockDisable || isDisabled || previewForm ? 'grey' : '#0D69D5'"
                  />
                  <path
                    d="M12.5542 0.164062C13.2621 0.164062 13.8359 0.737898 13.8359 1.4458V9.13623C13.8359 9.84413 13.2621 10.418 12.5542 10.418H4.86377C4.15587 10.418 3.58203 9.84413 3.58203 9.13623V1.4458C3.58203 0.737898 4.15587 0.164062 4.86377 0.164062H12.5542Z"
                    :fill="isCloneBlockEnable.length == 0 || isLinkBlockDisable || isDisabled || previewForm ? 'grey' : '#0D69D5'"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_11_358">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <!-- Clone button-->
            <button
              class="btn btn-ghost no-print cloneBtn mobile-hover-disable print--none"
              :class="{'cloneBtn':!isCollapsed || isDateRangeBlock && windowWidth> 1080|| !isDateRangeBlock, 'addRange': isDateRangeBlock && windowWidth < 499 , 'bgTransparent': previewForm, 'hideCloneBtn': previewForm,'cursor-not-allowed disabled:opacity-25': isCloneBlockEnable.length ==0, 'disableCloneBlock': isCloneBlockEnable.length==0}"
              @click="cloneEntity"
              v-if="canBeCopied && !formAlreadySubmitted"
              :title="getCloneTitleText"
              :disabled="previewForm || isDisabled || isLinkBlockDisable || formAlreadySubmitted"
            > Duplicate
            <svg height="14" viewBox="0 0 1792 1792" width="14" xmlns="http://www.w3.org/2000/svg">
              <path d="M1664 1632v-1088q0-13-9.5-22.5t-22.5-9.5h-1088q-13 0-22.5 9.5t-9.5 22.5v1088q0 13 9.5 22.5t22.5 9.5h1088q13 0 22.5-9.5t-9.5-22.5zm128-1088v1088q0 66-47 113t-113 47h-1088q-66 0-113-47t-47-113v-1088q0-66 47-113t113-47h1088q66 0 113 47t47 113zm-384-384v160h-128v-160q0-13-9.5-22.5t-22.5-9.5h-1088q-13 0-22.5 9.5t-9.5 22.5v1088q0 13 9.5 22.5t22.5 9.5h160v128h-160q-66 0-113-47t-47-113v-1088q0-66 47-113t113-47h1088q66 0 113 47t47 113z" fill="#0d69d5"/>
            </svg>

            </button>
            <button
              class="btn btn-ghost btn-sm btn-circle no-print delBtn"
              @click="removeEntity"
              v-if="(canBeRemoved || isLinkBlockDisable) && !formAlreadySubmitted"
              title="Delete Block"
              :disabled="previewForm || isDisabled || isLinkBlockDisable || formAlreadySubmitted"
              :class="{ bgTransparent: previewForm ,'delBtn': !isCollapsed || isDateRangeBlock && windowWidth> 1080|| !isDateRangeBlock}"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                :disabled="previewForm || formAlreadySubmitted"
              >
                <path
                  d="M1.04858 4.15038V12.7162C1.04858 13.7299 1.93236 14.5517 3.02184 14.5517H8.94202C10.0319 14.5517 10.9157 13.7299 10.9157 12.7162V4.15038H1.04858ZM3.68209 12.6227C3.68209 12.8454 3.53444 13.0256 3.35219 13.0256H3.02185C2.83937 13.0256 2.69174 12.8454 2.69174 12.6227L2.69174 5.77279C2.69174 5.55811 2.83025 5.38666 3.0032 5.37412H3.37022C3.54316 5.38666 3.68168 5.55813 3.68168 5.77279V12.6227H3.68209V12.6227ZM6.64215 12.6227C6.64215 12.8454 6.49452 13.0256 6.31224 13.0256H5.6518C5.46976 13.0256 5.3219 12.8454 5.3219 12.6227V5.77279C5.3219 5.55811 5.46044 5.38666 5.63336 5.37412H6.3303C6.50346 5.38666 6.64176 5.55813 6.64176 5.77279V12.6227H6.64215V12.6227ZM9.27233 5.77279V12.6228C9.27233 12.8456 9.12471 13.0257 8.94202 13.0257H8.61188C8.42984 13.0257 8.28221 12.8456 8.28221 12.6228V5.77279C8.28221 5.55811 8.42051 5.38666 8.59365 5.37412H8.96046C9.13401 5.38666 9.27233 5.55811 9.27233 5.77279ZM10.9157 1.70327H1.04858C0.685292 1.70327 0.390625 1.97734 0.390625 2.31505V2.62093C0.390625 2.95902 0.685292 3.23272 1.04858 3.23272H10.9157C11.2788 3.23272 11.5734 2.95902 11.5734 2.62093V2.31505C11.5734 1.97734 11.2792 1.70327 10.9157 1.70327Z"
                  :fill= "isLinkBlockDisable || previewForm ? 'grey' : '#FF0000'"
                />
                <path
                  d="M5.20614 0.173828H6.87518C7.2387 0.173828 7.53295 0.447899 7.53295 0.785604V1.09148C7.53295 1.42958 7.23828 1.70327 6.87518 1.70327H5.20614C4.84285 1.70327 4.54818 1.42958 4.54818 1.09148V0.785604C4.54818 0.447899 4.84285 0.173828 5.20614 0.173828Z"
                  :fill="isLinkBlockDisable || previewForm ? 'grey' : '#FF0000'"
                />
              </svg>
              <!-- Remove -->
            </button>
    </div>
        <small
          v-if="isError"
          class="text-red-600 inline-block text-sm flex flex-col"
        >
          <div v-for="(error, i) in blockData.error" :key="i">
            <span >
              {{ error }}
            </span>
          </div>
        </small>
  </div>
       
      <!--* /content -->
             
    </div>
    
  </div>
   <template v-if="showGapModal && windowWidth< 1080">

        <gapModal ref="gap-modal-2" 
                :section="section"
                :blockData="currentBlock"
                :validations="validations"
                :previewMode="previewMode"
                :previewForm="previewForm"
                :linkedBlock="linkedBlock"
                :showGapModal="showGapModal"
                @onClose="()=>showGapModal=false"
                class="my-4"
                :path="path"
                :isAdd="isAdd"
                :isEdit="isEdit"
                @addBlock="addBlock"
                @editTimeLine="editTimeLine"
                :currentBlockIndex="currentBlockIndex"
                :formAlreadySubmitted="formAlreadySubmitted"
    />
    </template>
  </div>
</template>

<script>
import Layout from "./layoutForm.vue";
import FormField from "./formField.vue";
import {
  checkIfMulti,
  getEntityCount,
  getEntityByKeyList,
  checkIfLastOrFirst,
} from "../utils";
import store, { mutations } from "../store";
const GapErrors = () => import("../components/gapErrors.vue");
import fieldTypes from "../utils/field-types";
import gapModal from "./gapModal";
import BlocksList from "./block"

const gapValidGroups = {
  [false]: {
    id: 'invalid',
    itemClass: 'invalid',
    itemContent: 'Gap',
    className: 'invalid',
    content: 'Invalid gaps',
  },
  [true]: {
    id: 'valid',
    itemClass: 'valid',
    itemContent: '',
    className: 'valid',
    content: 'Valid gaps',
  },
}

export default {
  props: {
    blockData: {
      type: Object,
      default: () => {},
    },
    section: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    path: Array,
    validations: {
      type: Object,
      default: () => null,
    },
    // is used to preview form before creating it in an admin panel:
    previewMode: {
      type: Boolean,
      default: false,
    },
    // is used to prview filled form before its submitting:
    previewForm: {
      type: Boolean,
      default: false,
    },
    isMulti: {
      type: Boolean,
      default: false,
    },
    grouped: {
      type: Boolean,
      default: false,
    },
    posInGroup: {
      type: Number,
      default: 0,
    },
    scrollToGap: {
      type: Boolean,
      default: true,
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    sIndex: {
      type: Number,
      default: 0
    },
    defaultCollapseState: {
      type: Boolean,
    },
    gapErrorMsg: String,
    formAlreadySubmitted: {
      type: Boolean,
    },
    checkBoxId: {
      type: String
    },
    findErrors:{
      type:Object
    }
  },
  components: {
    Layout,
    FormField,
    GapErrors,
    gapModal,
    BlocksList
  },
  data() {
    return {
      fieldTypes,
      checkIfMulti,
      store,
      isCollapsed: false,
      hasSignature: false,
      currentBlock: this.blockData,
      currentBlockIndex: 0,
      sectionData:{},
      showGapModal:false,
      isEdit: false,
      toggle: false,
      windowWidth: null,
      is_copy: false,
      isBlockDeleted: false,
      isChecked: false,
      firstBlockAdded: false,
      isDuplicateClick: false,
      isCrossBlock: false, 
      isSavedModal:false,
      duplicateBlocks: [],
      modalEdit:false
    };
  },
  mounted(){
    // this.showGapErrors()
    this.is_copy = false;
    if(this.defaultCollapseState){
      this.isCollapsed = this.defaultCollapseState;
    }
    this.hasSignature = this.blockData.entities.filter((field) => field.type === "CANDIDATE_SIGNATUTRE").length ? true :false;
    window.addEventListener('resize', this.handleWindowResize);
    this.getWindowSize();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  watch:{
    sections_data: {
      deep: true,
      handler() {
        this.sectionData = this.blockData;
      }
    }
  },
  computed: {
    sections_data() {
      return store.list;
    },
    isError() {
      return !!this.blockData.error?.length;
    },
    isBlockMulti() {
      return this.blockData.is_block_multi;
    },
    isBlockDuplicate() {
      return this.blockData.is_duplicate;
    },
    getBlockName() {
      return this.blockData?.block_name || "";
    },
    showMultiAddBtn() {
      return this.blockData.is_last || false;
    },
    isOriginal() {
      return (
        this.isBlockMulti &&
        this.blockData.block_id === this.blockData.block_fe_id
      );
    },
    isCopy() {
      return (
        (this.isBlockMulti &&
          this.blockData.block_id !== this.blockData.block_fe_id) ||
        this.blockData.is_copy
      );
    },
    canBeCopied() {
      return checkIfMulti(this.blockData, this.section.entities);
    },

    canBeRemoved() {
      return (
        this.isCopy &&
        getEntityCount(this.blockData, this.section.entities) >
          this.blockData.block_repeat_min
      );
    },
    isSavedCopy() {
      // is_copy is a temporary frontend property
      return this.isCopy && !this.blockData.is_copy;
    },
    gap_errors() {
      return store.gap_errors;
    },
    // blockGapErrors
    gapGroup: {
      get() {
        return store.gap_values[this.blockData.block_id] || null;
      },
      set(val) {
        this.$set(store.gap_values, this.blockData.block_id, val);
      },
    },
    showGapErrors() {
      let gapErrorValues = localStorage.getItem('gapErrorValue_'+this.gap_errors.id)
      return (
        this.gap_errors &&
        (this.gap_errors.gapErrorsView || gapErrorValues.gapErrorsView) &&
        this.gapGroup &&
        // this.isInvalidGap(this.gapGroup.timeline) &&
        this.posInGroup === 0
        && this.isShown
      );
    },
    linked_blocks() {
      return store.linked_blocks;
    },
    /* linked blocks are the same blocks with the same ranges of fields.
        All same blocks except first should be hidden.
        Values of those fields should be the same.
        As first block behavior is the same as default we don't consider
        the first block from linked_blocks list */
    // TODO consider case with empty section when all its blocks are linked and hidden
    linkedBlockGroup() {
      // check block_id to include copies in calculations:
      return (
        this.linked_blocks.find((group) =>
          group.includes(this.blockData.block_id)
        ) || null
      );
    },
    isLinked() {
      if (!this.linkedBlockGroup) return null;
      // check block_id to include copies in calculations:
      return this.linkedBlockGroup.some((el) => el === this.blockData.block_id);
    },
    // the first linked block of a group which values will be used:
    linkedBlock() {
      if (!this.isLinked || !this.blockData.use_value_from) return null;
      // check block_fe_id because only original block has same block_id and block_fe_id:
      return getEntityByKeyList(this.sections_data, {
        block_fe_id: this.blockData.use_value_from,
      });
    },
    isDisabled() {
      return !!this.linkedBlock;
    },
    isLinkBlockDisable() {
      return (!this.isDisabled && this.blockData.is_LinkedCopy)
    },
    isShown() {
      return this.blockData.visible;
    },
    isCloneBlockEnable () {
      return (
        this.blockData.entities.filter((el) => {
          if(el.field_type == 'Signature' && el.value != null) {
            return JSON.parse(el.value).value;
          }
          return el.value != null && el.value != "";
        })
      ) 
    },
    isDateRangeBlock () {
      const data = this.blockData?.entities.filter((el) => el.field_type === "Date Range (Timeline)")
      // const showDateRange = data[0]?.field_type;
      if(data[0]?.field_type === "Date Range (Timeline)") {
        return true;
      }
      return false;
    },
    getCloneTitleText() {
      return this.isCloneBlockEnable.length ? 'Add duplicate' : 'No field available to create duplicate!';
    },
  },
  created() {
    // if there are not enough block copies in the form create them:
    if (this.isBlockMulti && this.blockData.block_repeat_min) {
      let toRepeat =
        this.blockData.block_repeat_min -
        getEntityCount(this.blockData, this.section.entities);
      while (toRepeat > 0) {
        this.copyEntity();
        toRepeat--;
      }
    }
  },
  methods: {
    // showGapErrors() {
    //   // debugger //eslint-disable-line no-debugger
    //   let gapErrorValues = localStorage.getItem('gapErrorValue_'+this.gap_errors.id)
    //   console.log('gapErrorValues', JSON.parse(gapErrorValues));
    //   return (
    //     this.gap_errors &&
    //     (this.gap_errors.gapErrorsView || gapErrorValues.gapErrorsView) &&
    //     this.gapGroup &&
    //     // this.isInvalidGap(this.gapGroup.timeline) &&
    //     this.posInGroup === 0
    //     && this.isShown
    //   );
    // },

    onCross(){
      this.isCrossBlock = true;
    },
    isSaved(){
      this.isSavedModal = true;
    },
    handleCloseModal(currentBlock){
      let list = this.section.entities;
      if(list.length > 1 && !this.isSavedModal && this.isCrossBlock && currentBlock?.showFirstBlock){
        list.pop();
      }
      this.showGapModal = false; 
    }
    ,
    addBlock(){
      let list = this.section.entities;
      let previous = this.blockData;
      this.currentBlockIndex = list?.length;
      let value = {}
      const entity = this.store.utils.copyEntityWithoutSection(previous, list, value); 
      this.currentBlock = entity;
      this.isEdit=false;
      this.showGapModal=true;
      // this.$refs["gap-modal-2"].showModal();
    },
    editBlock(currentBlock, isEdit=false, blockIndex , firstAddBlock= false){
      this.currentBlockIndex = blockIndex
      this.isEdit = isEdit
      this.currentBlock = currentBlock;
      this.showGapModal=true
      if(this.isEdit && !firstAddBlock){
        this.modalEdit = true
      }
      // this.$refs["gap-modal-2"].showModal();

    },
    cloneBlock(currentBlock, blockIndex){
      this.currentBlockIndex = blockIndex + 1;
      const getValues = () => {
        let values = {};
        currentBlock.entities
          // skip fields which values shouldn't be copied:
          .filter(
            (el) => !(el.gap_validation && !el.gap_validation.overlap_allowed)
          )
          .map(
            (el) =>
              //  [el.field_fe_id]: el.value || null,
              (values[el.field_id] = el.value)
          );
        return values;
      }
      const newBlock =  this.store.utils.copyEntity({
        entity: currentBlock,
        list: this.section.entities,
        clone: true,
        values: getValues(),
        block_clone_id: this.blockData.block_id+'_'+this.blockData.block_fe_id+'_'+this.section.entities.length
      }); 
      if (this.gapGroup) {
        // set gap values for the corresponding timeline field:
        const timelineField = getEntityByKeyList(newBlock.entities, {
          field_id: this.gapGroup.field_id,
        });
        mutations.setFilledGap(timelineField, newBlock);
      }
      this.currentBlock = newBlock;
      this.showGapModal=true;
        // this.$refs["gap-modal-2"].showModal();
       
    },   
    checkCheckboxId(val){
      this.$emit("checkCheckboxId", val)
    },
    editTimeLine(){
            if(this.isEdit){
              let requiredIndex = "";
              for(let index in this.section.entities){
                if(this.section.entities[index].block_fe_id === this.currentBlock.block_fe_id) {
                  requiredIndex = index;
                }
              }
              this.store.utils.saveGapModalBlock({list: this.section.entities, currentBlock: this.currentBlock, requiredIndex: requiredIndex})
               
            }
            this.toggle = !this.toggle;
    },
    getFieldRef(field) {
      if (this.getFieldConfig(field).customTemplate)
        return `form-field-${this.getFieldConfig(field).customTemplate}`
      else return `form-field-${this.getFieldType(field)}`
    },
    getFieldConfig(field) {
        return this.fieldTypes[this.getFieldType(field)] || this.fieldTypes[this.getFieldBaseType(field)];
    },
    getFieldType(field) {
        return field?.field_type?.toLowerCase();
    },
    getFieldBaseType(field) {
        return field?.field_base_type?.toLowerCase();
    },
    toggleCollapse(payload = !this.isCollapsed) {
        this.isCollapsed = payload;
        this.$emit('collapseClicked');
        this.$emit('collapseChanged',this.blockData.block_fe_id,this.isCollapsed);
    },
    checkBoxData(val){
      this.isChecked = val;
    },
    onCopyEntity() {
      const entity = this.copyEntity();
      this.currentBlock = entity  
      // this.addNewBlock(entity)
      this.$nextTick(() => {
          const newBlockEl = document.getElementById(entity.block_fe_id);
          newBlockEl.scrollIntoView({ block: "start", behavior: "smooth" });
      });
      return entity;
    },

    copyEntity(previous = this.blockData, values = {}, addElemToLast) {
      return this.store.utils.copyEntity({
        entity: this.blockData,
        list: this.section.entities,
        values,
        previous,
        block_clone_id: this.blockData.block_id+'_'+this.blockData.block_fe_id+'_'+this.section.entities.length
      }, addElemToLast);
    },
    handleWindowResize() {
      this.getWindowSize();
    },
    getWindowSize() {
      this.windowWidth = window.innerWidth;
    },
    /**
     * same as copyEntity it just takes another arguement for values and clone flag as true
     */
    cloneEntity() {
      if(this.isCloneBlockEnable.length == 0) {   //Form should have at least one field filled to clone
        return;
      }
      // calculate value of fields of block
      // values = [{<field_iid>: <value>}...]
      const getValues = () => {
        let values = {};
        this.blockData.entities
          // skip fields which values shouldn't be copied:
          .filter(
            (el) => !(el.gap_validation && !el.gap_validation.overlap_allowed)
          )
          .map(
            (el) =>
              //  [el.field_fe_id]: el.value || null,
              (values[el.field_id] = el.value)
          );
        return values;
      };
      const newBlock =  this.store.utils.copyEntity({
        entity: this.blockData,
        list: this.section.entities,
        clone: true,
        values: getValues(),
        block_clone_id: this.blockData.block_id+'_'+this.blockData.block_fe_id+'_'+this.section.entities.length
        
      });
      if (this.gapGroup) {
        // set gap values for the corresponding timeline field:
        const timelineField = getEntityByKeyList(newBlock.entities, {
          field_id: this.gapGroup.field_id,
        });
        mutations.setFilledGap(timelineField, newBlock);
      }
      this.$nextTick(() => {
          const newBlockEl = document.getElementById(newBlock.block_fe_id);
          newBlockEl.scrollIntoView({ block: "start", behavior: "smooth" });
      });

      return newBlock;
    },

    /**
     *  check if the order of block is in last or first
     *  return { is_first: boolean; is_last: boolean}
     */
    isLastOrFirst(id, block_fe_id, isLast = false) {
      return checkIfLastOrFirst(this.section.entities, id, block_fe_id, isLast);
    },
    async removeEntity() {
      try {
        this.$emit("emtpyBlockFieldId",this.blockData.block_fe_id);
        await this.store.utils.removeEntity({
          entity: this.blockData,
          list: this.section.entities,
          is_saved: this.isSavedCopy,
          form_entity_type: this.blockData.form_entity_type,
        });
        // If block was created for the gap delete the record:
        this.removeTimeline();
      } catch (error) {
        console.log("can't remove block", error);
      }
      
    },
    async removeEntityBlock(deleteData) {
      try {
        await this.store.utils.removeEntity({
          entity: deleteData,
          list: this.section.entities,
          is_saved: this.isSavedCopy,
          form_entity_type: this.blockData.form_entity_type,
        });

        // If block was created for the gap delete the record:
        this.removeTimelineBlock(deleteData);
      } catch (error) {
        console.log("can't remove block", error);
      }
      
    },
  
    isLinkedBlockMulti(blockData) {
      return (blockData.is_block_multi)
    },
    isLinkedCopy(blockData) {
      return ((this.isLinkedBlockMulti(blockData) &&
          blockData.block_id !== blockData.block_fe_id) ||
        blockData.is_copy)
    },
    isLinkedSavedCopy(blockData) {
      return (this.isLinkedCopy(blockData) && !blockData.is_copy);
    },
    /**
     * set order of blocks
     * @param {Object} blockData the block object
     * @param {String} direction the direction block is moved in can be either 'up' or 'down'
     * 
     */
    handleOrder(blockData, direction) {
      // this.$emit('expandClicked');
      mutations.handleOrder(blockData, this.section.section_id, direction);
    },
    selectGap(gap) {
      if (!this.gapGroup || !this.previewForm) {
        if (!this.canBeCopied) {
          this.$toast.error(
            "Due to system limitations, it is not possible to add any more information for this gap. Please contact your system administrator to continue."
          );
          return;
        }

        const value = {
          from: gap.gapData.start,
          to: gap.gapData.end,
          current: true, //convert becaues when last gap clicked then present should be true
        };

        // add a new block after the last of the gaps group:
        // const prevBlock = this.section.entities.findLast((el) =>
        //   this.gapGroup.timeline.some(
        //     (block) => block.block_fe_id === el.block_fe_id
        //   )
        // );
        const prevBlock = this.section.entities.findLast((el) => this.gapGroup.timeline.filter((block) => block.block).some(item => item.block.block_fe_id === el.block_fe_id))
        // this.showGapModal=true
         
      // this.$refs["gap-modal-2"].showModal();
        
        const newBlock = this.copyEntity(prevBlock, {}, true); // true for adding element to last
        
        this.currentBlock = newBlock;
        
        // set gap values for the corresponding timeline field:
        const timelineField = getEntityByKeyList(newBlock.entities, {
          field_id: this.gapGroup.field_id,
        });
        timelineField.value = JSON.stringify(value);

        // console.log('timelineField :>> ', timelineField);

        mutations.setFilledGap(timelineField, newBlock, gap);

        // scroll to a new block:
        this.$nextTick(() => {
          const newBlockEl = document.getElementById(newBlock.block_fe_id);
          newBlockEl.scrollIntoView({ block: "start", behavior: "smooth" });
        });
        this.firstBlockAdded = true;
        this.showGapModal = true;
        // this.$refs["gap-modal-2"].showModal();
      }
    },
    // showGapModal(){
    //   this.$refs["gap-modal-2"].showModal();
    // },
    // addAnotherBlock(block) {
    //     EventBus.$emit("addNewBlock", { block, block_id: block._id });
    // },
    // removeBlock(block) {
    //     EventBus.$emit("removeBlock", { block,_id: block._id });
    // }
    scrollToGaps() {
    //   showGapModal(){
    //   this.$refs["gap-modal-2"].showModal();
    // },
    
      if (this.isInvalidGap() && this.scrollToGap) {
        if (this.$refs["scrollRef"].$refs['scroll-save']) {
          this.$refs["scrollRef"].$refs['scroll-save'].scrollIntoView({ behavior: "smooth" });
          // this.$emit("disableScrollToGap");
        }
      } 
    },
    addTimeline(field) { 
      if (!this.gapGroup) return;
      let userInputDates = [];

      this.section.entities.forEach((entity) => {
          if (entity.block_id === this.blockData.block_id && entity.form_entity_type == 'BLOCK') {
              const dateRange = entity.entities
              ?.filter((ent) => ent.value && ent.field_type === "Date Range (Timeline)" && ent.gap_validation)
              .map(ent => {
                //  let isSafari = window.safari !== undefined;
                // if(isSafari){
                  const {from ,to } =JSON.parse(ent.value);
                  const startDate = from.split(" ")[0]; 
                  const endDate =  to.split(" ")[0];
                  return ({start: new Date(startDate.toString()), 
                   end: new Date(endDate.toString()), block: entity, field: ent})
                // }else{
                //   return ({start: new Date(JSON.parse(ent.value).from), 
                //    end: new Date(JSON.parse(ent.value).to), block: entity, field: ent})
                // }
              }  
              );
              userInputDates.push(...dateRange);
          }
      });
      const timelines = this.store.utils.findGapPeriods(userInputDates);

      this.$set(this.gapGroup, "timeline", timelines);
      
      // remove previous record:
      this.removeTimeline();
      mutations.setFilledGap(field, this.blockData);
    },
    removeTimeline() { 
      if (!this.gapGroup) return;
      const gapToRemove = this.gapGroup.timeline.find(
        (el) => el.block?.block_fe_id === this.blockData.block_fe_id
      );
      // reset gaps list if there is gap to remove:
      if (gapToRemove)
        this.gapGroup.timeline = this.gapGroup.timeline.filter(
          (el) => el.block?.block_fe_id !== this.blockData.block_fe_id
        );
    },
    removeTimelineBlock(deleteData) { 
      if (!this.gapGroup) return;
      const gapToRemove = this.gapGroup.timeline.find(
        (el) => el.block?.block_fe_id === deleteData.block_fe_id
      );
      // reset gaps list if there is gap to remove:
      if (gapToRemove)
        this.gapGroup.timeline = this.gapGroup.timeline.filter(
          (el) => el.block?.block_fe_id !== deleteData.block_fe_id
        );
    },
    removeLinkedTimeline(blockData) {
      if (!this.store.gap_values[blockData.block_id]) return;
      const gapToRemove = this.store.gap_values[blockData.block_id].timeline.find(
        (el) => el.block?.block_fe_id === blockData.block_fe_id
      );
      // reset gaps list if there is gap to remove:
      if (gapToRemove)
        this.store.gap_values[blockData.block_id].timeline = this.store.gap_values[blockData.block_id].timeline.filter(
          (el) => el.block?.block_fe_id !== blockData.block_fe_id
        );
    },

    getGapItem(payload) {
      // const isValid = false; // for testing
      const isValid = !payload.invalid_gap;
      const group = gapValidGroups[isValid];
      return {
        gapData: payload,
        start: new Date(payload.start),
        end: new Date(payload.end),
        content: group.itemContent,
        group: group.id,
        className: `gap truncate cursor-pointer gap--${group.itemClass}`,
        title: `
          <div>
            <div>
              <b>From:</b>
              <span>${payload.start}</span>
            </div>
            <div class="flex gap-1">
              <b>To:</b>
              <span>${payload.end}</span>
            </div>
          </div>`,
      }
    },
    isInvalidGap() {
      // added this check because this function can be called for the blocks which does not have gap timeline.
      if(!this.gapGroup) {
        return false;
      }
      const validGaps = this.gapGroup.timeline
        .filter(gap => !gap.invalid_gap)
        .filter(gap => gap.field.value)
        .map(gap => {
          const gapValue = JSON.parse(gap.field.value);
          return {
            start: gapValue.from,
            end: gapValue.to,
            invalid_gap: false,
          }
        })
        .map(this.getGapItem);

      const invalidGaps = this.gapGroup.timeline
        .filter(gap => gap.invalid_gap)
        .filter(gap => {
          return !(validGaps
            .some(timeline =>
              new Date(timeline.start).getTime() <= new Date(gap.end).getTime()
              && new Date(timeline.end).getTime() >= new Date(gap.start).getTime())
            );
        })
        .map(gap => {
          return {
            start: gap.start,
            end: gap.end,
            invalid_gap: true,
          }
        })
        .map(this.getGapItem);
        
      return invalidGaps.length > 0 ;
    },
    
  },
};
</script>

<style style="scss" scoped>
.disableCloneBlock:hover {
  background-color: transparent;
}
.bgTransparent{
  background-color: transparent !important;
  display: none !important;
}
.hideLabel{
  display:none;
  background: none !important;
}
.blockCount{
  display: none;
}
.svgCollapseBlock{
    display: none;
  }
.tableView {
  display:none ;
}
.blockView{
  display:block;
}

@media screen and (min-width: 300px) and (max-width: 500px)  {
  .nativeBlockView {
    position: relative;
    top: 20px;
    /* right: 13px; */
  }
  .blockFields {
    margin-bottom: 25px;
  }
  .AddButton{
    width: 7.5rem ;
  }
  .addRange{
    display: none !important ;
  }
  .newBlockDate{
    display: block !important;
  }
  .svgCollapse{
    display: block;
  }
  .boederWidthDate {
    border-top: 5px solid #DDDDDD !important;
}
}
/*@media screen and (min-width: 300px) and (max-width: 500px)  {
  // .nativeBlockView {
  //   position: relative;
  //   top: 20px;
  //   right: 13px;
  // }*/
  .blockHeader{
    /*border: 2px solid red;*/
    background-color: #e7f1fb !important;
    margin: -20px -16px 0px -16px;
    border-top-right-radius: 13px;
    border-top-left-radius: 13px;
    height: 74px;
  }
  /* .blockItems{
    padding-left: 1rem !important;
  } */
  .form_block{
   /* border: 1px solid gray;
    /*padding: 2rem;*/
    background: white !important;
    overflow: hidden;
  }

  .openBlock {    
    padding-bottom: 40px;
  }
   .cloneBtn{
    color:  #0D6AD5;
    border: 1px solid #0D6AD5;
    text-transform: capitalize;
    width: 8rem;
    border-radius: 8px;
    display: flex ;
    min-height: 37px !important;
    height: 41px !important;
  }
  .AddButton{
    color:  #0D6AD5;
    display: block;
    border: 1px solid #0D6AD5;
    text-transform: capitalize;
    border-radius: 8px;
    width: 7.8rem;
    margin: 0 10px 0 0;
    min-height: 37px !important;
    height: 41px !important;
  } 
  .btnClone, .addBlock{
    display: none;
  }
  .hideAddBtn, .hideCloneBtn{
    display: none !important;
  }
  .cloneAdd_block{
    justify-content: flex-start !important;
  }
  .hideBtn {
    display: none !important;
  }
  .svgCollapse{
    display: none;
  }
  .svgCollapseBlock{
    display: block;
    &:hover {
      background-color: transparent;
    }
  }
  .svgCollAll{
    margin-top: 18px;
  }
  .blockCount{
    display: block;
    border: 1px solid #0D6AD5;
    background: #0D6AD5;
    border-radius:33px !important;
    color: white;
    padding: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    font-weight: bolder;
    margin: 0px 17px 0 0px;
  }
  .blockLable{
    font-weight: bold !important;
    margin-top: 15px;
  }
  .delBtn{
    border: 1px solid red;
    border-radius: 8px !important;
    margin: 2px 10px;
    width: 39px;
    height: 44px;
    min-height: 37px !important;
    height:37px !important;
  }
  .cloneBtn svg{
    margin-left: 4px;
  }
  .borderWidth {
    border-top: 12px solid #b7cae0;
  }
  .borderHeader{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .blockLableData{
    display: none;
  }
  .disableCloneBlock{
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;
  }
  .removeOnlyButton {
    margin-bottom: 10px;
  }
  .addCloneBlock {
    display: none;
  }
/*}*/
@media screen and (min-width: 320px){
  .responsive_blockView{
    margin-left:10%;
  }
}

@media screen and (min-width: 360px){
  .responsive_blockView{
    margin-left:10%;
  }
}

@media screen and (min-width: 501px){
  .responsive_blockView{
    margin-left: 0 !important;
  }
  .blockItems-preview{
    padding-left: 0 !important;
  }
  .blockNameLable{
    margin-top: 15px;
  }
}

@media screen and (max-width: 1080px) {
   .tableView {
    display: block;
  }
  .blockView {
    display: block;
  }
  .dateBlockHide{
    display:  none !important;
  }
  .mobile-hover-disable{
    background-color: inherit !important; 
    color: #0D6AD5 !important;
  }

  .collapesBlock {
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px !important;
    height: 62px;
  }
}

@media screen and (max-width: 500px){
  .tableView {
    display: block;
  }
  .blockView {
    display: block;
  }
  .dateBlockHide{
    display:  none !important;
  }
}
 
@media screen and (min-width: 360px) and (max-width: 375px) {
  .nativeFormBlock {
    width: 258px;
  }
}


</style>